export const zcDateFormat = (dateStr) => {
  //const dateStr = "2024-10-27 07:52:21";
  const date = new Date(dateStr);

  // month
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  //"DD-MMM-YYYY"
  const formattedDate = `${date.getDate()}-${
    months[date.getMonth()]
  }-${date.getFullYear()}`;

  return formattedDate;
};
