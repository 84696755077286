import { HashRouter as Router, Route, Routes } from 'react-router-dom';

import OrderDetails from './components/OrderDetails';
import NotFound from './components/NotFound';

import './App.css';

function App() {
  return (
    <div className="container">
      <Router>
        <Routes>
          <Route path="OrderDetails/:id" element={<OrderDetails />} />

          <Route path="/*" element={<NotFound />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
