import React from 'react';
import { Flex, Spinner, Text } from '@chakra-ui/react';

const LoadingPage = () => {
  return (
    <Flex height="100vh" align="center" justify="center" direction="column">
      <Spinner
        thickness="4px"
        speed="0.65s"
        emptyColor="gray.200"
        color="blue.500"
        size="xl"
      />
      <Text mt={4} fontSize="lg" color="gray.600">
        Se încarcă, va rog așteptați...
      </Text>
    </Flex>
  );
};

export default LoadingPage;
