import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import LoadingPage from './LoadingPage';
import MessageBox from './MessageBox';

import { zcDateFormat } from '../utility/zcFormatDate';

import {
  Box,
  Button,
  Flex,
  Heading,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Image,
} from '@chakra-ui/react';
// import orderSample from "../../json/order.json";

const OrderDetails = () => {
  const params = useParams();

  const remoteApi =
    'https://usersordersportal-866385090.development.catalystserverless.com/api';

  //const remoteApi = "http://localhost:3000/server/users_orders_portal_function";

  const [orderData, setOrderData] = useState({});

  const [orderId, setOrderId] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [isOrderCompleted, setIsOrderCompleted] = useState(true);
  const [itemImages, setItemImages] = useState([]);

  const fetchProductId = async (sku) => {
    try {
      const response = await axios.get(
        `${remoteApi}/products/search/sku/${encodeURIComponent(sku)}`
      );
      if (response?.data?.message === 'success') {
        return Object.keys(response?.data?.data)[0];
      }
      return null;
    } catch (e) {
      return null;
    }
  };

  const fetchImageUrl = async (productId) => {
    try {
      const response = await axios.get(
        `${remoteApi}/products/images/${productId}`
      );

      if (response?.data?.message === 'success') {
        return response?.data?.data[0]?.url;
      }
      return null;
    } catch (e) {
      return null;
    }
  };

  useEffect(() => {
    setOrderId(params.id);
  }, [params.id]);

  useEffect(() => {
    const getOrderData = async () => {
      setIsLoading(true);

      try {
        const response = await axios.get(`${remoteApi}/order/${orderId}`);

        if (response?.data?.message === 'success') {
          setOrderData(response?.data?.data);

          setIsOrderCompleted(
            response?.data?.data?.status === 'complete' ? true : false
          );
          //display images
          let _itmes = [...response?.data?.data?.order_items];

          // Fetch product details and image URL in parallel
          _itmes.map(async (item) => {
            const productId = await fetchProductId(item?.sku);
            if (!productId) {
              return;
            }
            const imageUrl = await fetchImageUrl(productId);
            setItemImages((prevData) => ({
              ...prevData,
              [item.item_id]: {
                imageUrl: imageUrl,
              },
            }));
          });
        } else {
          setIsError(true);
        }
      } catch (err) {
        setIsError(true);
      } finally {
        setIsLoading(false);
      }
    };
    if (orderId !== null && orderId !== '') {
      getOrderData();
    }
  }, [orderId]);

  const returAndGarantie = (type, orderData, item) => {
    const shippingAddress = orderData?.addresses?.find(
      (_address) => _address?.address_type === 'billing'
    );

    const Nume_Prenume = `${shippingAddress?.firstname ?? ''} ${
      shippingAddress?.lastname ?? ''
    }`;
    let Factura_este_pe = 'Persoana fizica';
    if (shippingAddress?.company !== null) {
      Factura_este_pe = 'Persoana juridica';
    }

    const completeComments = orderData?.order_comments?.filter(
      (comment) => comment.status === 'complete'
    );
    const minDate = new Date(
      Math.min(
        ...completeComments.map((comment) => new Date(comment.created_at))
      )
    );

    const param = `Tip_Solicitare=${type}&Numar_comanda_factura=${
      orderData?.entity_id
    }&Cod_produs=${
      item?.sku
    }&Factura_este_pe=${Factura_este_pe}&Nume_Prenume=${Nume_Prenume}&Nume_firma=${
      shippingAddress?.company ?? ''
    }&Email=${shippingAddress?.email ?? ''}&Numar_telefon=${
      shippingAddress?.telephone ?? ''
    }&&Max_Qty=${
      (Number(item?.qty_ordered?.split('.')[0]) || 0) -
        (Number(item?.qty_refunded?.split('.')[0]) || 0) || 1
    }&Sales_Order_Date=${zcDateFormat(minDate)}`;

    window.open(
      'https://creatorapp.zohopublic.com/spyshop/order-management-4-0/form-perma/Ticket_form/W66tPVJfBKqugafS2qn6VNOHHSMmQ5xnNtG8JDVYw0P6XuxF9fFkFUs49r3gzvJGqCU4d0JnmRdZ1RwfhNPhxFzhZYR5H1au21Qs?' +
        param,
      '_blank',
      'noopener,noreferrer'
    );
  };

  if (isLoading) {
    return <LoadingPage />;
  }

  if (isError) {
    return (
      <MessageBox
        primaryText="Oops! Ceva nu a mers ok."
        SecondaryText="Nu putem accesa datele comenzii."
      />
    );
  }

  return (
    <Box mt="3rem" p={5} maxWidth="1100px" mx="auto">
      {/* Order Number Header */}

      <Heading fontWeight="normal" size="xl" mb={5}>
        Detalii comanda nr. {orderData?.entity_id}
      </Heading>

      {/* Order Status */}
      <Button
        borderRadius="sm"
        size="xs"
        colorScheme="orange"
        variant="solid"
        mb={5}
      >
        {orderData?.status}
      </Button>

      {!isOrderCompleted ? (
        <MessageBox
          primaryText="Aceasta comanda nu a fost inca livrata."
          SecondaryText="Pentru a putea solicita un retur sau pentru o cerere de garantie comanda trebuie sa fie livrata."
        />
      ) : (
        <Box
          border="1px solid #E2E8F0"
          borderRadius="md"
          mt={5}
          overflow="hidden"
        >
          <Box>
            <Table variant="simple">
              <Thead h={20} bg="gray.100">
                <Tr>
                  <Th>Produs</Th> <Th></Th>
                  <Th>Cod produs</Th>
                  <Th>Cantitate</Th>
                  <Th>Subtotal</Th>
                  <Th>Solicitare</Th>
                </Tr>
              </Thead>
              <Tbody>
                {orderData?.order_items?.map((item) => (
                  <Tr key={item?.item_id}>
                    <Td>
                      {itemImages[item?.item_id]?.imageUrl !== null &&
                      itemImages[item?.item_id]?.imageUrl !== undefined ? (
                        <Image
                          src={itemImages[item?.item_id]?.imageUrl}
                          alt={'-'}
                          boxSize="125px"
                          mr={3}
                          minW={'125px'}
                        />
                      ) : (
                        <Box
                          boxSize="125px"
                          minW={'125px'}
                          minHeight={'125px'}
                          mr={3}
                        ></Box>
                      )}
                    </Td>
                    <Td>
                      <Text>{item?.name}</Text>
                    </Td>
                    <Td minWidth={150}>
                      <Text fontWeight="semibold">{item?.sku}</Text>
                    </Td>
                    <Td minWidth={100}>
                      {Number(item?.qty_ordered?.split('.')[0]) -
                        Number(item?.qty_refunded?.split('.')[0])}{' '}
                      buc.
                    </Td>
                    <Td minWidth={150}>
                      <Text align="right">
                        {item?.row_total_incl_tax?.slice(0, -2) ??
                          item?.base_row_total?.slice(0, -2)}{' '}
                        lei
                      </Text>
                    </Td>
                    <Td minWidth={150}>
                      <Flex align="center" justify="center" gap={5}>
                        <Button
                          minWidth={20}
                          borderRadius="sm"
                          size="xs"
                          colorScheme="blue"
                          variant="solid"
                          onClick={() =>
                            returAndGarantie('Retur', orderData, item)
                          }
                        >
                          Retur
                        </Button>

                        <Button
                          minWidth={20}
                          borderRadius="sm"
                          size="xs"
                          colorScheme="orange"
                          variant="solid"
                          onClick={() =>
                            returAndGarantie('Garantie', orderData, item)
                          }
                        >
                          Garantie
                        </Button>
                      </Flex>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>

          <Box mt={3}>
            <Table variant="unstyled">
              <Tbody>
                <Tr>
                  <Td>
                    <Text>Cost total:</Text>
                  </Td>
                  <Td>
                    <Text align="right">
                      {orderData?.grand_total?.slice(0, -2)} lei
                    </Text>
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default OrderDetails;
