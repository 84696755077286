import { createRoot } from 'react-dom/client';

import { ChakraProvider } from '@chakra-ui/react';

import theme from './theme';

import App from './App';

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(
  <ChakraProvider theme={theme}>
    <App />
  </ChakraProvider>
);
