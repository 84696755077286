import React from 'react';
import { Text, Box } from '@chakra-ui/react';

const MessageBox = ({ primaryText, SecondaryText }) => {
  return (
    <>
      <Box
        textAlign="center"
        p={6}
        maxW="xl"
        borderWidth="1px"
        borderRadius="lg"
        bg="red.50"
        borderColor="red.200"
        my={10}
        mx="auto"
      >
        <Text fontSize="lg" color="red.800">
          {primaryText}
        </Text>
        <Text fontSize="sm" color="gray.600" mt={2}>
          {SecondaryText}
        </Text>
      </Box>
    </>
  );
};

export default MessageBox;
