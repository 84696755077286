import { Box, Heading, Text, VStack } from '@chakra-ui/react';

const NotFound = () => {
  return (
    <Box
      minH="100vh"
      display="flex"
      alignItems="center"
      justifyContent="center"
      px={6}
    >
      <VStack spacing={4} textAlign="center">
        <Heading as="h1" fontSize="6xl" color="red.500">
          404
        </Heading>
        <Text fontSize="2xl">
          Oops! Pagina pe care incerci sa o accesezi nu exista.
        </Text>
      </VStack>
    </Box>
  );
};

export default NotFound;
